// @ts-check
// let acc = document.getElementsByClassName("accordion");
// let i;

// for (i = 0; i < acc.length; i++) {
//   acc[i].addEventListener("click", function() {
//     /* Toggle between adding and removing the "active" class,
//     to highlight the button that controls the panel */
//     this.classList.toggle("active");

//     /* Toggle between hiding and showing the active panel */
//     let panel = this.nextElementSibling;
//     if (panel.style.display === "block") {
//       panel.style.display = "none";
//     } else {
//       panel.style.display = "block";
//     }
//   });
// }

// Credit::
// https://stackoverflow.com/questions/37745154/only-open-one-accordion-tab-at-one-time
// https://jsfiddle.net/5h5evjpw/




let acc = document.getElementsByClassName("accordion");
let panel = document.getElementsByClassName('panel');

for (let i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function(event) {
    	let setClasses = !this.classList.contains('active');
        setClass(acc, 'active', 'remove');
        setClass(panel, 'show', 'remove');
        
       	if (setClasses) {
            this.classList.toggle("active");
            this.nextElementSibling.classList.toggle("show");
        }
    })
}

function setClass(els, className, fnName) {
    for (let i = 0; i < els.length; i++) {
        els[i].classList[fnName](className);
    }
}


// get .panel, check to see if .active, if not, display none? 
export default { acc };