// @ts-check
import { 
    siteNameCopy,
    // subTitleCopy,
} from "../copy/title_copy"

let navHeader = document.querySelector('header');

// new title element 
let makeTitle = document.createElement('h2');

navHeader.appendChild(makeTitle);

let headerLink = document.createElement('a');
headerLink.setAttribute('href', 'index.html');
headerLink.appendChild(siteNameCopy);
makeTitle.appendChild(headerLink);
// let breakLine = document.createElement('br');
// makeTitle.appendChild(breakLine);
// makeTitle.appendChild(subTitleCopy);

// clone title element to footer
let navFooter = document.querySelector('footer');
let navClone = makeTitle.cloneNode(true);
navFooter.appendChild(navClone);
