// first column nav copy
export const navAa = document.createTextNode("Logos + Identity"); 
export const navAb = document.createTextNode("OneRx Brand"); 
export const navAc = document.createTextNode("Truveris Identity"); 
export const navAd = document.createTextNode("Various Logos"); 

    // exports
    export const navAaCopy = navAa.cloneNode(true); 
    export const navAbCopy = navAb.cloneNode(true); 
    export const navAcCopy = navAc.cloneNode(true); 
    export const navAdCopy = navAd.cloneNode(true); 

// first column nav copy
export const navBa = document.createTextNode("UI/UX + Coding"); 
export const navBb = document.createTextNode("OneRx iOS"); 
export const navBc = document.createTextNode("OneRx Web App"); 
export const navBd = document.createTextNode("Static Sites"); 
export const navBe = document.createTextNode("Banners & Emails"); 

    // exports
    export const navBaCopy = navBa.cloneNode(true); 
    export const navBbCopy = navBb.cloneNode(true); 
    export const navBcCopy = navBc.cloneNode(true); 
    export const navBdCopy = navBd.cloneNode(true); 
    export const navBeCopy = navBe.cloneNode(true); 

    // first column nav copy
export const navCa = document.createTextNode("Print + Illustration"); 
export const navCb = document.createTextNode("Data Visualization"); 
export const navCc = document.createTextNode("Iconography"); 
export const navCd = document.createTextNode("Architecture & Engineering"); 

    // exports
    export const navCaCopy = navCa.cloneNode(true); 
    export const navCbCopy = navCb.cloneNode(true); 
    export const navCcCopy = navCc.cloneNode(true); 
    export const navCdCopy = navCd.cloneNode(true);

    // first column nav copy
export const navDa = document.createTextNode("Technical Stuff"); 
export const navDb = document.createTextNode("Front End Development"); 
export const navDc = document.createTextNode("2D & 3D Illustration"); 
export const navDd = document.createTextNode("Product Management & Design"); 
export const navDe = document.createTextNode("Print Production"); 
export const navDf = document.createTextNode("Video & Motion Graphics"); 

    // exports
    export const navDaCopy = navDa.cloneNode(true); 
    export const navDbCopy = navDb.cloneNode(true); 
    export const navDcCopy = navDc.cloneNode(true); 
    export const navDdCopy = navDd.cloneNode(true); 
    export const navDeCopy = navDe.cloneNode(true); 
    export const navDfCopy = navDf.cloneNode(true); 


