// @ts-check

// append first column element ***
let getBody = document.getElementById('app');

// new element ***
let makeFooter = document.createElement('footer');
getBody.append(makeFooter);

let makeBase = document.createElement('div');
makeBase.classList.add('footer-base');
getBody.append(makeBase);

// let footerSelect = document.createElement('select');
// makeBase.appendChild(footerSelect);

// let selectOptOne = document.createElement('option');
// footerSelect.appendChild(selectOptOne);

// let optTextOne = document.createTextNode('Design');
// selectOptOne.appendChild(optTextOne);

let footerText = document.createTextNode('Design Portfolio | Coded from scratch with <3 by Jason Merritt | \u00A9 2021');
makeBase.appendChild(footerText);