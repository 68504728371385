// @ts-check
import { 
    navAaCopy,
    navAbCopy,
    navAcCopy,
    navAdCopy,
    navBaCopy,
    navBbCopy,
    navBcCopy,
    navBdCopy,
    navBeCopy,
    navCaCopy,
    navCbCopy,
    navCcCopy,
    navCdCopy,
    navDaCopy,
    navDbCopy,
    navDcCopy,
    navDdCopy,
    navDeCopy,
    navDfCopy,
} from "../copy/nav_copy"


// !!! RESPONSIVE MENU+ACTIONS AT BOTTOM


// append first column element ***
let navHeader = document.querySelector('header');

// new element ***
let makeNav = document.createElement('nav');
navHeader.appendChild(makeNav);

let ulOne = document.createElement('ul');
makeNav.appendChild(ulOne);

    let liOne = document.createElement('li');
    ulOne.appendChild(liOne);

        let liOneLink = document.createElement('h3');
        liOne.appendChild(liOneLink);
        liOneLink.appendChild(navAaCopy);
        liOneLink.classList.add('accordion');

    let olOne = document.createElement('ol');
    liOne.appendChild(olOne);
    olOne.classList.add('panel');

    let liOneC = document.createElement('li');
    olOne.appendChild(liOneC);

        let liOneCLink = document.createElement('a');
        liOneCLink.setAttribute('href', 'ad_logos.html');
        liOneC.appendChild(liOneCLink);
        liOneCLink.appendChild(navAdCopy);

        let liOneA = document.createElement('li');
        olOne.appendChild(liOneA);

            let liOneALink = document.createElement('a');
            liOneALink.setAttribute('href', 'ad_onerx.html');
            liOneA.appendChild(liOneALink);
            liOneALink.appendChild(navAbCopy);

        let liOneB = document.createElement('li');
        olOne.appendChild(liOneB);

            let liOneBLink = document.createElement('a');
            liOneBLink.setAttribute('href', 'ad_truveris.html');
            liOneB.appendChild(liOneBLink);
            liOneBLink.appendChild(navAcCopy);
        


// append second column element ***
let ulTwo = document.createElement('ul');
makeNav.appendChild(ulTwo);

    let liTwo = document.createElement('li');
    ulTwo.appendChild(liTwo);

        let liTwoLink = document.createElement('h3');
        liTwo.appendChild(liTwoLink);
        liTwoLink.appendChild(navBaCopy);
        liTwoLink.classList.add('accordion');

    let olTwo = document.createElement('ol');
    liTwo.appendChild(olTwo);
    olTwo.classList.add('panel');

        let liTwoC = document.createElement('li');
        olTwo.appendChild(liTwoC);

            let liTwoCLink = document.createElement('a');
            liTwoCLink.setAttribute('href', 'ts_frontend.html');
            liTwoC.appendChild(liTwoCLink);
            liTwoCLink.appendChild(navDbCopy);

        let liTwoA = document.createElement('li');
        olTwo.appendChild(liTwoA);

            let liTwoALink = document.createElement('a');
            liTwoALink.setAttribute('href', 'ui_mobile.html');
            liTwoA.appendChild(liTwoALink);
            liTwoALink.appendChild(navBbCopy);

        let liTwoB = document.createElement('li');
        olTwo.appendChild(liTwoB);

            let liTwoBLink = document.createElement('a');
            liTwoBLink.setAttribute('href', 'ui_webapp.html');
            liTwoB.appendChild(liTwoBLink);
            liTwoBLink.appendChild(navBcCopy);
        

            // let liTwoD = document.createElement('li');
            // olTwo.appendChild(liTwoD);
    
            //     let liTwoDLink = document.createElement('a');
            //     liTwoDLink.setAttribute('href', 'ui_banner.html');
            //     liTwoD.appendChild(liTwoDLink);
            //     liTwoDLink.appendChild(navBeCopy);


// append third column element ***
let ulThree = document.createElement('ul');
makeNav.appendChild(ulThree);

    let liThree = document.createElement('li');
    ulThree.appendChild(liThree);

        let liThreeLink = document.createElement('h3');
        liThree.appendChild(liThreeLink);
        liThreeLink.appendChild(navCaCopy);
        liThreeLink.classList.add('accordion');

    let olThree = document.createElement('ol');
    liThree.appendChild(olThree);
    olThree.classList.add('panel');

        let liThreeA = document.createElement('li');
        olThree.appendChild(liThreeA);

            let liThreeALink = document.createElement('a');
            liThreeALink.setAttribute('href', 'pd_dataviz.html');
            liThreeA.appendChild(liThreeALink);
            liThreeALink.appendChild(navCbCopy);

        let liThreeB = document.createElement('li');
        olThree.appendChild(liThreeB);

            let liThreeBLink = document.createElement('a');
            liThreeBLink.setAttribute('href', 'pd_iconography.html');
            liThreeB.appendChild(liThreeBLink);
            liThreeBLink.appendChild(navCcCopy);

        let liThreeC = document.createElement('li');
        olThree.appendChild(liThreeC);

            let liThreeCLink = document.createElement('a');
            liThreeCLink.setAttribute('href', 'ts_print.html');
            liThreeC.appendChild(liThreeCLink);
            liThreeCLink.appendChild(navDeCopy);


// append fourth column element ***
// let ulFour = document.createElement('ul');
// makeNav.appendChild(ulFour);

//     let liFour = document.createElement('li');
//     ulFour.appendChild(liFour);

//         let liFourLink = document.createElement('h3');
//         liFour.appendChild(liFourLink);
//         liFourLink.appendChild(navDaCopy);
//         liFourLink.classList.add('accordion');

//     let olFour = document.createElement('ol');
//     liFour.appendChild(olFour);
//     olFour.classList.add('panel');

//         let liFourA = document.createElement('li');
//         olFour.appendChild(liFourA);

//             let liFourALink = document.createElement('a');
//             liFourALink.setAttribute('href', 'ts_frontend.html');
//             liFourA.appendChild(liFourALink);
//             liFourALink.appendChild(navDbCopy);

//         let liFourB = document.createElement('li');
//         olFour.appendChild(liFourB);

//             let liFourBLink = document.createElement('a');
//             liFourBLink.setAttribute('href', 'ts_illustration.html');
//             liFourB.appendChild(liFourBLink);
//             liFourBLink.appendChild(navDcCopy);

//         let liFourC = document.createElement('li');
//         olFour.appendChild(liFourC);

//             let liFourCLink = document.createElement('a');
//             liFourCLink.setAttribute('href', 'ts_product.html');
//             liFourC.appendChild(liFourCLink);
//             liFourCLink.appendChild(navDdCopy);
        

//         let liFourD = document.createElement('li');
//         olFour.appendChild(liFourD);

//             let liFourDLink = document.createElement('a');
//             liFourDLink.setAttribute('href', 'ts_print.html');
//             liFourD.appendChild(liFourDLink);
//             liFourDLink.appendChild(navDeCopy);

        

//         let liFourE = document.createElement('li');
//         olFour.appendChild(liFourE);

//             let liFourELink = document.createElement('a');
//             liFourELink.setAttribute('href', 'ts_motion.html');
//             liFourE.appendChild(liFourELink);
//             liFourELink.appendChild(navDfCopy);


// responsive button ***
let menuButton = document.createElement('div');
menuButton.classList.add('menu-button');
navHeader.appendChild(menuButton);

// button actions ***
menuButton.addEventListener('click', function (event) {
    makeNav.classList.toggle('show');
    menuButton.classList.toggle('on');
});

// add hamburger class ***
let heavenSpan = document.createElement('span');
menuButton.appendChild(heavenSpan);
heavenSpan.classList.add('hamburglar')

//clone the nav
let navFooter = document.querySelector('footer');
let navClone = makeNav.cloneNode(true);
navFooter.appendChild(navClone);

// let getOls = document.querySelectorAll('ol');
// console.log(getOls);

// export ***
export default { navHeader }; 

