// @ts-check
// append app element ***
const appLoader = document.querySelector('#app'); 
let app = document.querySelector('#app');

// app element ***
let makeLoader = document.createElement('div');
app.prepend(makeLoader);
makeLoader.setAttribute('id', 'loader')

let loaderSpinner = document.createElement('div');
makeLoader.appendChild(loaderSpinner);
loaderSpinner.setAttribute('id', 'spinner')
appLoader.appendChild(makeLoader);

// loader events ***
document.getElementById("loader").style.display = "block";

window.onload = function() {
  document.getElementById("loader").style.display = "none";
}

// export *********************************************************************************************
export default { appLoader };